import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Dropdown from "react-bootstrap/Dropdown";
import "./style.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { updateBreadCrumb } from "../../app/features/Common/CommonSlice";
import { campaignManagerSelector } from "../../app/features/CampaignManager/CampaignManagerSlice";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";

import { toast } from "react-hot-toast";
import Moment from "react-moment";
import Loader from "../Loader";
import { downloadCsv } from '../../utils/index';

const ReportsSaas = () => {
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const [dateDisable, setDateDisable] = useState(true);
  const [loadershown, setloadershown] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedCampaignsId, setSelectedCampaignsId] = useState([]);
  const [formatData, setFormatData] = useState([]);
  const [selectedFormats, setSelectedFormats] = useState([]);
  const [daterange, setrange] = useState("");
  const [campaignData, setCampaignData] = useState([]);
  const [advertiseData, setAdvertiseData] = useState([]);
  const [startend, setStartend] = useState({ start: "", end: "" });
  const [campaign, setCampaign] = useState("");
  const [format, setFormat] = useState("");
  const [advertiser, setAdvertiser] = useState([]);
  const [advertiserId, setAdvertiserId] = useState([]);
  const [period, setPeriod] = useState("");
  const [dimension, setDimension] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const [impressions, setImpressions] = useState(true);
  const [views, setViews] = useState(false);
  const [clicks, setClicks] = useState(false);
  const [engagements, setEngagements] = useState(false);
  const [customDate, setCustomDate] = useState(false);
  const [cpcv, setcpcv] = useState(false);
  const [ctr, setctr] = useState(false);
  const [egRate, setegRate] = useState(false);
  const [showResults, setShowResults] = useState(true);
  const [overviewData, setOverviewData] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [impressionView, setImpressionView] = useState(false);
  const [viewsView, setViewsView] = useState(false);
  const [clicksView, setClicksView] = useState(false);
  const [EngagmentView, setEngagmentView] = useState(false);
  const [VtrView, setVtrView] = useState(false);
  const [CtrView, setCtrView] = useState(false);
  const [ErView, setErView] = useState(false);
  const [periodError, setPeriodError] = useState(false);
  const [modifyingFormat, setModifyingFormat] = useState([])
  const [dataPeriod, setDataPeriod] = useState(" ")
  const [spent, setSpent] = useState(false)
  const [spentCurrency, setSpentCurrency] = useState(false)
  const [cpm, setCPM] = useState(false)
  const [cpv, setCPV] = useState(false)
  const [advertiserSaas, setAdvertiserSaas] = useState([])
  const permission = JSON.parse(localStorage.getItem('userdata'));
  const [selected_advertiser_saas, setSelected_advertiser_saas] = useState([permission?.AdvertiserId])
  const [asc, setAsc] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false);
  const [dimension_Options, setDimension_Options] = useState()
  const [startEndDate, setStartEndDate] = useState(false)
  const [generalReport, setGeneralReport] = useState(true);
  const [showPlacementsReport, setShowPlacementsReport] = useState(false);
  const [showPlacementDescription, setShowPlacementDescription] = useState(true);
  const [lineitemSelected, setLineitemSelected] = useState(null);
  const [placementData, setPlacementData] = useState([]);
  const [sortConfig, setSortConfig] = useState({});
  const [showPlacementDataNotFound, setShowPlacementDataNotFound] = useState(false);

  const refImpressionView = useRef(impressionView);
  const refViews = useRef(viewsView);
  const refClicks = useRef(clicksView);
  const refEngagements = useRef(EngagmentView);
  const refVtr = useRef(VtrView);
  const refCtr = useRef(CtrView);
  const refEr = useRef(ErView);
  const refCpm = useRef(cpm);
  const refCpv = useRef(cpv);
  const refSpentUsd = useRef(spent);
  const refSpentCurrencyDeal = useRef(spentCurrency);
  const dateRef = useRef(startEndDate)

  const { deal_detail, dealCurrency, isFetching, isSuccess, isError, error } =
    useSelector(campaignManagerSelector);
  const navigation = useNavigate();
  const removeEmptyParams = (url) => {
    return encodeURI(url.replace(/[^=&]+=(&|$)/g, "").replace(/&$/, ""));
  };

  useEffect(() => {
    let dimensionOptions;

    if (dimension.includes("LineItem")) {
      dimensionOptions = [
        { name: "Campaign", value: "campaign" },
        { name: "Format", value: "format" },
        { name: "Creative", value: "creative" },
        { name: "Date", value: "date" },
        { name: "Line Item", value: "LineItem" },
        { name: "Offer Type", value: "OfferType" },
        { name: "Inventory Type", value: "inventory_type" }
      ];
      setDimension_Options(dimensionOptions)
    } else {
      dimensionOptions = [
        { name: "Client", value: "Client" },
        { name: "Campaign", value: "campaign" },
        { name: "Format", value: "format" },
        { name: "Creative", value: "creative" },
        { name: "Date", value: "date" },
        { name: "Line Item", value: "LineItem" }
      ];
      setDimension_Options(dimensionOptions)
    }
  }, [dimension])

  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("DD/MM/YYYY") +
      " - " +
      picker.endDate.format("DD/MM/YYYY")
    );
    setStartend({
      start: picker.startDate.format("YYYY-MM-DD"),
      end: picker.endDate.format("YYYY-MM-DD"),
    });
  };
  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  function refreshPage() {
    window.location.reload();
  }

  let searchquery = `creation_date=${daterange}&startDate=${startend.start
    }&endDate=${startend.end
    }&campaign=${campaign}&format=${format}&period=${period}&advertiser=${advertiser}&dimension=${dimension}&filterDate=${filterDate}&impressions=${impressions ? impressions : ""
    }&views=${views ? views : ""}&clicks=${clicks ? clicks : ""}&engagements=${engagements ? engagements : ""
    }&cpcv=${cpcv ? cpcv : ""}&ctr=${ctr ? ctr : ""}&egRate=${egRate ? egRate : ""
    }`;

  const changeSelectMetrics = () => {
    if (selectedAll === false) {
      setSelectedAll(true);
      refImpressionView.current.checked = true;
      setImpressionView(true);

      refViews.current.checked = true
      setViewsView(true)

      refClicks.current.checked = true
      setClicksView(true)

      refEngagements.current.checked = true
      setEngagmentView(true)

      refVtr.current.checked = true
      setVtrView(true)

      refCtr.current.checked = true
      setCtrView(true)

      refEr.current.checked = true
      setErView(true)

      refCpm.current.checked = true
      setCPM(true)

      refCpv.current.checked = true
      setCPV(true)

      refSpentUsd.current.checked = true
      setSpent(true)

      refSpentCurrencyDeal.current.checked = true
      setSpentCurrency(true)

      if (dimension?.includes("LineItem")) {
        dateRef.current.checked = true
        setStartEndDate(true)
      }

    } else {
      setSelectedAll(false);
      refImpressionView.current.checked = false;
      setImpressionView(false);

      refViews.current.checked = false
      setViewsView(false)

      refClicks.current.checked = false
      setClicksView(false)

      refEngagements.current.checked = false
      setEngagmentView(false)

      refVtr.current.checked = false
      setVtrView(false)

      refCtr.current.checked = false
      setCtrView(false)

      refEr.current.checked = false
      setErView(false)

      refCpm.current.checked = false
      setCPM(false)

      refCpv.current.checked = false
      setCPV(false)

      refSpentUsd.current.checked = false
      setSpent(false)

      refSpentCurrencyDeal.current.checked = false
      setSpentCurrency(false)

      if (dimension?.includes("LineItem")) {
        dateRef.current.checked = false
        setStartEndDate(false)
      }
    }
  }

  const handleSelectChange = (event) => {
    let value = event.target.value;
    setDataPeriod(value)
    if (value === "custom") {
      setCustomDate(true);
    } else {
      setCustomDate(false);
    }
    value == "custom" ? setDateDisable(false) : setDateDisable(true);
    if (value != "custom") {
      searchquery = removeEmptyParams(
        `creation_date=&startDate=&endDate=&campaign=${campaign}&format=${format}&period=${value}&advertiser=${advertiser}&dimension=${dimension}&filterDate=${filterDate}&impressions=${impressions ? impressions : ""
        }&views=${views ? views : ""}&clicks=${clicks ? clicks : ""
        }&engagements=${engagements ? engagements : ""}&cpcv=${cpcv ? cpcv : ""
        }&ctr=${ctr ? ctr : ""}&egRate=${egRate ? egRate : ""}`
      );
      // fetchReports();
      setPeriod(value);
      setStartend({ ...startend, start: "", end: "" });
    } else {
      setPeriod("");
    }
  };
  const datechange = (events) => {
    let dimnesionValue = events.target.value;
    dimnesionValue == "date" ? setShowResults(true) : setShowResults(false);
    if (dimnesionValue == "date") {
      setFilterDate("");
    }
  };
  const findpercetage = (array, element) => {
    let sum = array
      .map((item) => item.impressions)
      .reduce((prev, curr) => prev + curr, 0);
    let percentage =
      sum == 0
        ? 0
        : (
          (array
            .map((item) => item[element])
            .reduce((prev, curr) => prev + curr, 0) /
            sum) *
          100
        ).toFixed(2);
    return percentage;
  };
  const usedimension = (e) => {
    const selecteddimensions = e.map((val) => val.value).filter(Boolean);
    setDimension(selecteddimensions);
  };
  const usedatefilter = (newdatefilter) => {
    searchquery = removeEmptyParams(
      `creation_date=${daterange}&startDate=${startend.start}&endDate=${startend.end
      }&campaign=${campaign}&format=${format}&period=${period}&advertiser=${advertiser}&dimension=${dimension}&filterDate=${newdatefilter}&impressions=${impressions ? impressions : ""
      }&views=${views ? views : ""}&clicks=${clicks ? clicks : ""
      }&engagements=${engagements ? engagements : ""}&cpcv=${cpcv ? cpcv : ""
      }&ctr=${ctr ? ctr : ""}&egRate=${egRate ? egRate : ""}`
    );

    setFilterDate(newdatefilter);
  };
  const handleCampainChange = (campaignName) => {
    searchquery = removeEmptyParams(
      `creation_date=${daterange}&startDate=${startend.start}&endDate=${startend.end
      }&campaign=${campaignName}&format=${format}&period=${period}&advertiser=${advertiser}&dimension=${dimension}&filterDate=${filterDate}&impressions=${impressions ? impressions : ""
      }&views=${views ? views : ""}&clicks=${clicks ? clicks : ""
      }&engagements=${engagements ? engagements : ""}&cpcv=${cpcv ? cpcv : ""
      }&ctr=${ctr ? ctr : ""}&egRate=${egRate ? egRate : ""}`
    );

    setCampaign(campaignName);
  };

  const handleFormatChange = (formatName) => {
    searchquery = removeEmptyParams(
      `creation_date=${daterange}&startDate=${startend.start}&endDate=${startend.end
      }&campaign=${campaign}&format=${formatName}&period=${period}&advertiser=${advertiser}&dimension=${dimension}&filterDate=${filterDate}&impressions=${impressions ? impressions : ""
      }&views=${views ? views : ""}&clicks=${clicks ? clicks : ""
      }&engagements=${engagements ? engagements : ""}&cpcv=${cpcv ? cpcv : ""
      }&ctr=${ctr ? ctr : ""}&egRate=${egRate ? egRate : ""}`
    );

    setFormat(formatName);
  };

  const handleAdvertiserChange = (advertiserName) => {
    let arr1 = [];
    arr1.push(advertiserName);

    setAdvertiser(arr1);
  };

  const exportCsv = () => {
    const csvData = [
      [
        resultData?.data?.Client ? 'Client' : undefined,
        resultData?.data?.date ? 'Date' : undefined,
        resultData?.data?.campaign ? 'Campaign' : undefined,
        resultData?.data?.format ? 'Format' : undefined,
        resultData?.data?.country ? 'Country' : undefined,
        resultData?.data?.creative ? 'Creatives' : undefined,
        startEndDate ? 'Start Date' : undefined,
        startEndDate ? 'End Date' : undefined,
        resultData?.data?.LineItem ? 'Line Item' : undefined,
        resultData?.data?.OfferType ? 'Offer Type' : undefined,
        resultData?.data?.inventory_type ? 'Inventory Type' : undefined,
        impressionView ? 'Impressions' : undefined,
        viewsView ? 'Views' : undefined,
        clicksView ? 'Clicks' : undefined,
        EngagmentView ? 'Engagements' : undefined,
        VtrView ? 'VTR' : undefined,
        CtrView ? 'CTR' : undefined,
        ErView ? 'ER' : undefined,
        spent ? 'Spent' : undefined,
        spentCurrency ? 'SpentCurrency' : undefined, // ver {spentCurrency && <th>Spent ({resultData?.data?.overview[0]?.currencyCode})</th>}
        cpm ? 'CPM USD' : undefined,
        cpv ? 'CPV USD' : undefined
      ].filter((item) => item !== undefined),
      ...resultData?.data?.overview?.map((item) => ([
        resultData?.data?.Client ? (item.client ? item.client : "Not Available") : undefined,
        resultData?.data?.date ? item.date : undefined,
        resultData?.data?.campaign ? item.campaign : undefined,
        resultData?.data?.format ? item.format : undefined,
        resultData?.data?.country ? item.country : undefined,
        resultData?.data?.creative ? item.creative : undefined,
        startEndDate ? item.startDate : undefined,
        startEndDate ? item.endDate : undefined,
        resultData?.data?.LineItem ? item.lineitem : undefined,
        resultData?.data?.OfferType ? item.offer : undefined,
        resultData?.data?.inventory_type ? item.inventioryType : undefined,
        impressionView ? String(item.impressions).replaceAll(',', '') : undefined,
        viewsView ? String(item.views).replaceAll(',', '') : undefined,
        clicksView ? String(item.clicks).replaceAll(',', '') : undefined,
        EngagmentView ? String(item.engagements).replaceAll(',', '') : undefined,
        VtrView ? (item.views != 0 ? item?.vtr : '-') + '%' : undefined,
        CtrView ? item.ctr + '%' : undefined,
        ErView ? item.er + '%' : undefined,
        spent ? (item.spentUsd ? item.spentUsd.toFixed(2) : 0.00) : undefined,
        spentCurrency ? (item.spent ? item.spent.toFixed(2) : 0.00) : undefined,
        cpm ? item.cpm.toFixed(2) : undefined,
        cpv ? item.cpv.toFixed(4) : undefined
      ].filter((item) => item !== undefined)),
      )];
    let date = new Date();
    downloadCsv('report-' + date.getDate() + (date.getMonth() + 1) + date.getFullYear() + '.csv', csvData);
  }

  const exportCsvPlacements = () => {
    const csvData = [
      [
        'Placement Name',
        'Audience',
        'Gender',
        'Impressions',
        'Complete Views',
        'VTR',
        lineitemSelected.type !== "Youtube" ? 'CTR' : undefined,
        lineitemSelected.type !== "Youtube" ? 'Clicks' : undefined,
        'Spent',
      ].filter((item) => item !== undefined),
      ...placementData?.map((item) => ([
        item.placementName,
        item.audience,
        item.gender,
        item.impressions,
        item.completeViews,
        item.vtr != null ? item.vtr.toFixed(2) + '%' : '-',
        lineitemSelected.type !== "Youtube" ? (item.ctr != null ? item.ctr.toFixed(2) : '-') + '%' : undefined,
        lineitemSelected.type !== "Youtube" ? item.clicks : undefined,
        item.spent != null ? item.spent.toFixed(2) : '-',
      ].filter((item) => item !== undefined)),
      )];
    let date = new Date();
    downloadCsv('report-' + date.getDate() + (date.getMonth() + 1) + date.getFullYear() + '.csv', csvData);
  }

  useEffect(() => {
    if (permission.role == 1) {
      getAdvertiserData();
      getCampaignsList([], null)
      getClientsData([])
    } else {
      getCampaignsList([permission.AdvertiserId], null)
      getClientsData([permission.AdvertiserId]);
    }
    getFormat();
  }, []);

  const getAdvertiserData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}ReportSaas/get_reportadveriser`, requestOptions)
      .then(response => response.json())
      .then(result => setAdvertiserSaas(result?.data))
      .catch(error => toast.error(error));
  }

  const getCampaignsList = (advertiserList, clientsId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "advertiser_client_Id": advertiserList,
      "clientsId": clientsId
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}ReportSaas/get_reportcampaign`, requestOptions)
      .then(response => response.json())
      .then(result => { setCampaignData(result?.data?.Campaign) })
      .catch(error => toast.error(error));
  }

  const getClientsData = async (advertiserList) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "advertiserid": advertiserList
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}ReportSaas/get_client`, requestOptions)
      .then(response => response.json())
      .then(result => { setAdvertiseData(result?.data) })
      .catch(error => toast.error(error));
  };

  useEffect(() => {
    if (setSelectedCampaignsId?.length !== 0) {
      getCampaignBasedFormat()
    }
  }, [selectedCampaignsId])

  const getCampaignBasedFormat = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "campaign_Id": selectedCampaignsId
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}report/get_reportformat`, requestOptions)
      .then(response => response.json())
      .then(result => { setFormatData(result?.data?.Formats) })
      .catch(error => toast.error(error));
  }

  const getFormat = async () => {
    const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/formats`,
      {
        method: "GET",
        headers: {
          AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const result = await response.json();
    if (result) {
      setFormatData(result?.data);
    }
  };

  const handle_multiselect_advertiser_saas = (e) => {
    const selected_advertiser_saas = e.map((val) => val?.id);
    setSelected_advertiser_saas(selected_advertiser_saas);
    getCampaignsList(selected_advertiser_saas, null);
    getClientsData(selected_advertiser_saas);
  }

  const handle_multiremove_advertiser_saas = (e) => {
    var selected_advertiser_saas = e.map((val) => val.id);
    setSelected_advertiser_saas(selected_advertiser_saas);
    getCampaignsList(selected_advertiser_saas, null)
    getClientsData(selected_advertiser_saas);
  }

  const handleCampaign = (e) => {
    const selectedCampaigns = e.map((val) => val?.name);
    setSelectedCampaigns(selectedCampaigns);
  };

  const handleFormat = (e) => {
    const selectedFormats = e.map((val) => val?.name).filter(Boolean);
    setSelectedFormats(selectedFormats);
  };

  const handle_multiselect_advertiser = (e) => {
    var allSelectedAdvertiser = e.map((val) => val.companyName).filter(Boolean);
    setAdvertiser(allSelectedAdvertiser);

    var allSelectedAdvertiserId = e.map((val) => val.id).filter(Boolean);
    setAdvertiserId(allSelectedAdvertiserId);
  };

  const handle_multiselect_clients = (e) => {
    var allSelectedAdvertiser = e.map((val) => val.companyName).filter(Boolean);
    setAdvertiser(allSelectedAdvertiser);

    var allSelectedAdvertiserId = e.map((val) => val.id).filter(Boolean);
    setAdvertiserId(allSelectedAdvertiserId);

    getCampaignsList(selected_advertiser_saas, allSelectedAdvertiserId)
  };

  const handleSearch = async (field) => {
    if (dataPeriod !== "custom" && !period) {
      toast.error("Please select period");
    } else if (startend.start === "" && startend.end === "" && dataPeriod === "custom") {
      toast.error("Please select dates");
    } else if (dimension?.length === 0) {
      toast.error("Please select Dimensions");
    } else {
      setloadershown(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      var raw = JSON.stringify({
        advertiser: advertiser,
        campaign: selectedCampaigns,
        format: selectedFormats,
        startDate: startend.start ? startend.start : null,
        endDate: startend.end ? startend.end : null,
        dimension: dimension,
        period: period ? period : null,
        impressions: true,
        views: true,
        clicks: true,
        engagements: true,
        ctr: true,
        egRate: true,
        spent_currency_deal: true,
        cpv: true,
        cpm: true,
        advertiserId: selected_advertiser_saas ? selected_advertiser_saas[0] === null ? [] : selected_advertiser_saas : [],
        FieldName: field,
        Order: asc ? "asc" : "desc"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}ReportSaas/get_reportoverview`, requestOptions)
        if (!response.ok) {
          const errorMessage = await response.json();
          const statusCode = response.status;
          if (errorMessage) {
            throw new Error(errorMessage.message);
          }
        }
        const result = await response.json();
        setResultData(result);
        setOverviewData(result?.data?.overview);
        if (dimension.includes('LineItem') && advertiser.length > 0 && selectedCampaigns.length > 0) {
          setShowPlacementDescription(false);
        } else {
          setShowPlacementDescription(true);
        }
        setGeneralReport(true);
        setShowPlacementsReport(false);
        setPlacementData([]);
        setLineitemSelected(null);
      } catch (error) {
        toast.error(error)
      }
      finally {
        setloadershown(false);
      }
    }
  };

  const handlePlacementData = async (lineitemId) => {
    if (dataPeriod !== "custom" && !period) {
      toast.error("Please select period");
    } else if (startend.start === "" && startend.end === "" && dataPeriod === "custom") {
      toast.error("Please select dates");
    } else if (lineitemId === null) {
      toast.error("Please select a lineitem");
    } else {
      setloadershown(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      var raw = JSON.stringify({
        startDate: startend.start ? startend.start : null,
        endDate: startend.end ? startend.end : null,
        period: period ? period : null,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}reportsaas/get_placement_report/${lineitemId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setPlacementData(result?.data);
          setloadershown(false);
          if (result?.data?.length === 0) {
            setShowPlacementDataNotFound(true);
          } else {
            setShowPlacementDataNotFound(false);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }

  const handle_multiremove_Campaign = (e) => {
    let removeCampaign = e.map((el) => el.id);
    setSelectedCampaigns(removeCampaign);
  };

  const handle_multiremove_format = (e) => {
    let removeFormat = e.map((el) => el.name);
    setSelectedFormats(removeFormat);
  };

  const handle_multiremove_dimension = (e) => {
    let removeDimension = e.map((el) => el.value);
    setDimension(removeDimension);
  };

  const handle_multiremove_clients = (e) => {
    let removeAdvertiser = e.map((el) => el.handle_multiselect_advertiser);
    setAdvertiser(removeAdvertiser);
  };

  const handleReportGeneral = (e) => {
    e.preventDefault();
    setGeneralReport(true);
    setShowPlacementsReport(false);
  }

  const handleReportPlacements = (e) => {
    e.preventDefault();
    setGeneralReport(false);
    setShowPlacementsReport(true);
  }

  const sortData = (data, key, direction = 'asc') => {
    return data.sort((a, b) => {
      let comparison = 0;
      if (a[key] < b[key]) {
        comparison = -1;
      } else if (a[key] > b[key]) {
        comparison = 1;
      }
      return direction === 'desc' ? comparison * -1 : comparison;
    });
  };

  const handleSortPlacements = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sortedData = sortData(placementData, key, direction);
  };

  const handleSort = (field) => {
    if (!asc) {
      overviewData.sort((a, b) => {
        if (typeof a[field] === 'string') {
          return a[field].localeCompare(b[field]);
        } else {
          return a[field] - b[field];
        }
      });
    } else {
      overviewData.sort((a, b) => {
        if (typeof a[field] === 'string') {
          return b[field].localeCompare(a[field]);
        } else {
          return b[field] - a[field];
        }
      });
    }
    let new_data = overviewData.slice();
    setOverviewData(new_data);
  }

  return (
    <>
      <div className="content_outer">
        <div className="divTitlePerformanceReportWelcome">
          <h5>This Reports section will no longer be available after October 14th</h5>
        </div>
        <Loader showLoader={loadershown} />
        <div className="content pb-0">
          <div className="App">
            <div className="reports__wrapper">
              <Form>
                <Row>
                  <Col lg={4}>
                    <div
                      className={
                        periodError
                          ? "input_wrap select_country_container mt-1 _border"
                          : "input_wrap select_country_container mt-1"
                      }
                    >
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="center_label lbl_position form-label">Date Range</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            handleSelectChange(e);
                          }}
                        >
                          <option disabled={true} selected={true}>
                            Select
                          </option>
                          <option value="yesterday">Yesterday</option>
                          <option value="month">So far this month</option>
                          <option value="seven_days">Last seven days</option>
                          <option value="last_month">Last month</option>
                          <option value="custom">Custom date</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Col>
                  {customDate && (
                    <Col md={3} lg={4}>
                      <div className="input_wrap select_country_container mt-1">
                        <Form.Group className="center_label lbl_position form-label" controlId="formBasicEmail">
                          <Form.Label>Custom Date</Form.Label>
                          <DateRangePicker
                            initialSettings={{
                              autoUpdateInput: false,
                              showDropdowns: true,
                            }}
                            onApply={handleApply}
                            onCancel={handleCancel}
                            disabled={true}
                          >
                            <Form.Control
                              type="text"
                              placeholder="Custom Date"
                              className="form-control"
                              disabled={dateDisable}
                            />
                          </DateRangePicker>
                        </Form.Group>
                      </div>
                    </Col>
                  )}
                  {permission?.role === 1 && <Col lg={4}>
                    <div className="input_wrap select_country_container mt-1">
                      <div className="form-group ">
                        <label className="center_label lbl_position form-label" htmlFor="Campaign">
                          Advertiser Saas
                        </label>
                        <Multiselect
                          options={advertiserSaas?.map((i) => ({
                            ...i,
                            name: i?.name,
                            displayValue: i.country ? `${i.name} - ${i.country}` : i.name,
                          }))}
                          onSelect={(e) => handle_multiselect_advertiser_saas(e)}
                          onRemove={(e) => handle_multiremove_advertiser_saas(e)}
                          displayValue="displayValue"
                        />
                      </div>
                    </div>
                  </Col>}

                  <Col lg={4} md={4}>
                    <div className="input_wrap select_country_container mt-1">
                      <label className="center_label lbl_position form-label" htmlFor="Campaign">
                        Clients
                      </label>
                      <Multiselect
                        options={advertiseData.map((i) => {
                          const name = i?.companyName ?? "Unknown";
                          return { ...i, name };
                        })}
                        // selectedValues={adv}
                        onSelect={(e) => { handle_multiselect_clients(e) }}
                        onRemove={(e) => handle_multiremove_clients(e)}
                        displayValue="name"
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="input_wrap select_country_container mt-1">
                      <div className="form-group">
                        <label className="center_label lbl_position form-label" htmlFor="Campaign">
                          Campaign
                        </label>
                        <Multiselect
                          options={campaignData?.map((i) => ({
                            ...i,
                            name: i?.name,
                          }))}
                          // selectedValues={selectedCampaigns}
                          onSelect={(e) => handleCampaign(e)}
                          onRemove={(e) => handle_multiremove_Campaign(e)}
                          displayValue="name"
                          className="reportOverflow"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="input_wrap select_country_container mt-1">
                      <div className="form-group mb-3">
                        <label className="center_label lbl_position form-label" htmlFor="country">
                          Dimensions
                        </label>
                        <Multiselect
                          options={dimension_Options}
                          // selectedValues={dimension}
                          onSelect={(e) => usedimension(e)}
                          onRemove={(e) => handle_multiremove_dimension(e)}
                          displayValue="name"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="metrics mt-5">
                      <div className="metricsFlex">
                        <h2>Metrics</h2>
                        <Button
                          onClick={changeSelectMetrics}
                          className="btnMetrics"
                        >
                          {selectedAll ? 'Deselect all' : 'Select all'}

                        </Button>
                      </div>
                      <ul className="mt-3">
                        <li>
                          <Form.Check
                            label="Impressions"
                            name="impressions"
                            type="checkbox"
                            ref={refImpressionView}
                            onChange={(e) => setImpressionView(!impressionView)}
                          />
                        </li>
                        <li>
                          <Form.Check
                            label="Views"
                            name="views"
                            type="checkbox"
                            ref={refViews}
                            onChange={(e) => setViewsView(!viewsView)}
                          />
                        </li>
                        <li>
                          <Form.Check
                            label="Clicks"
                            name="clicks"
                            type="checkbox"
                            ref={refClicks}
                            onChange={(e) => setClicksView(!clicksView)}
                          />
                        </li>
                        <li>
                          <Form.Check
                            label="Engagements"
                            name="engagements"
                            type="checkbox"
                            ref={refEngagements}
                            onChange={(e) => setEngagmentView(!EngagmentView)}
                          />
                        </li>
                        <li>
                          <Form.Check
                            label="VTR"
                            name="cpcv"
                            type="checkbox"
                            ref={refVtr}
                            onChange={(e) => setVtrView(!VtrView)}
                          />
                        </li>
                        <li>
                          <Form.Check
                            label="CTR"
                            name="ctr"
                            type="checkbox"
                            ref={refCtr}
                            onChange={(e) => setCtrView(!CtrView)}
                          />
                        </li>
                        <li className="me-3">
                          <Form.Check
                            label="ER"
                            name="egRate"
                            type="checkbox"
                            ref={refEr}
                            onChange={(e) => setErView(!ErView)}
                          />
                        </li>
                        <li className="me-3">
                          <Form.Check
                            label="CPM"
                            name="cpm"
                            type="checkbox"
                            ref={refCpm}
                            onChange={(e) => setCPM(!cpm)}
                          />
                        </li>
                        <li className="me-3">
                          <Form.Check
                            label="CPV"
                            name="cpv"
                            type="checkbox"
                            ref={refCpv}
                            onChange={(e) => setCPV(!cpv)}
                          />
                        </li>
                        <li className="me-3">
                          <Form.Check
                            label="Spent (USD)"
                            name="spent"
                            type="checkbox"
                            ref={refSpentUsd}
                            onChange={(e) => setSpent(!spent)}
                          />
                        </li>
                        <li className="me-3">
                          <Form.Check
                            label="Spent Currency Deal"
                            name="spent"
                            type="checkbox"
                            ref={refSpentCurrencyDeal}
                            onChange={(e) => setSpentCurrency(!spentCurrency)}
                          />
                        </li>
                        {dimension?.includes("LineItem") ? <li>
                          <Form.Check
                            label="Start/End Dates"
                            name="start/end Dates"
                            type="checkbox"
                            ref={dateRef}
                            onChange={(e) => setStartEndDate(!startEndDate)}
                          />
                        </li> : null}

                      </ul>
                    </div>

                    {overviewData?.length !== 0 && (
                      <div className="goals_card">
                        <ul>
                          {impressionView && <li>
                            <p> Impressions</p>
                            <h4>
                              {resultData?.data?.Impression?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </h4>
                          </li>}
                          {viewsView && <li>
                            <p>Completed Views</p>
                            <h4>{resultData?.data?.Views?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</h4>
                          </li>}
                          {clicksView && <li>
                            <p>Clicks</p>
                            <h4>
                              {parseFloat(
                                resultData?.data?.Click
                              ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </h4>
                          </li>}
                          {EngagmentView && <li>
                            <p>Engagement</p>
                            <h4>
                              {parseFloat(
                                resultData?.data?.Engagements
                              ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </h4>
                          </li>}
                          {VtrView && <li>
                            <p>VTR</p>
                            <h4>
                              {parseFloat((parseFloat(resultData?.data?.Views) / parseFloat(resultData?.data?.Impression)) * 100)?.toLocaleString('en-US', { maximumFractionDigits: 2 }) + "%"}
                            </h4>
                          </li>}
                          {CtrView && <li>
                            <p>CTR</p>
                            <h4>
                              {resultData?.data?.Sumctr?.toFixed(2).toLocaleString('en-US', { maximumFractionDigits: 2 }) + "%"}
                            </h4>
                          </li>}
                          {ErView && <li>
                            <p>ER</p>
                            <h4>
                              {parseFloat(
                                resultData?.data?.SumEr
                              ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </h4>
                          </li>}
                          {cpm && <li>
                            <p>CPM USD</p>
                            <h4>
                              {parseFloat(
                                resultData?.data?.SumCpm
                              )?.toFixed(2).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </h4>
                          </li>}
                          {cpv && <li>
                            <p>CPV USD</p>
                            <h4>
                              {parseFloat(
                                resultData?.data?.SumCpv
                              )?.toFixed(4).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </h4>
                          </li>}
                          {spent && <li>
                            <p>Spent USD</p>
                            <h4>
                              {parseFloat(
                                resultData?.data?.SumSpentUsd
                              )?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </h4>
                          </li>}
                          {spentCurrency && <li>
                            <p>Spent Currency</p>
                            <h4>
                              {parseFloat(
                                resultData?.data?.SumSpent
                              ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </h4>
                          </li>}
                        </ul>
                      </div>
                    )}
                    <div></div>
                  </Col>
                  <Col sm={12}>
                    <div className="resetBtns report_actions">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ opacity: "0" }}>Reset</Form.Label>{" "}
                        <br />
                        <Button
                          variant="outline-danger me-4"
                          onClick={refreshPage}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="outline-success"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>

                <div className="">
                  <Row></Row>
                </div>
              </Form>
            </div>
          </div>
          {resultData?.data?.overview?.length === 0 && (
            <div className="d-flex w-100 justify-content-center card no_data_found">
              <img className="no_data_found" src="/assets/images/notfound.svg" />
            </div>
          )}
          {overviewData?.length > 0 && (
            <Row>
              <Col sm={6} className={`reportTypeTab ${generalReport ? 'colLineItemTitleListBorderBottom' : ''}`}>
                <button onClick={handleReportGeneral} className="buttonReportTypeTab">
                  General
                </button>
              </Col>
              <Col sm={6} className={`reportTypeTab ${!generalReport ? 'colLineItemTitleListBorderBottom' : ''}`}>
                <button onClick={handleReportPlacements} className="buttonReportTypeTab">
                  Placements
                </button>
              </Col>
            </Row>
          )}
        </div>

        {(impressions == true ||
          views == true ||
          clicks == true ||
          engagements == true ||
          cpcv == true ||
          ctr == true ||
          egRate == true) && !showPlacementsReport && (
            <div className="content">
              <div className="card report__table">
                {(resultData?.data?.overview && resultData?.data?.overview?.length !== 0) &&
                  <div className="text-end pt-2 pb-2 pe-2">
                    <button className="button expo_button" onClick={exportCsv}>
                      Export
                    </button>
                  </div>
                }

                {overviewData?.length !== 0 && (

                  <Table bordered responsive>
                    <thead>
                      <tr>
                        {resultData?.data?.Client && <th onClick={() => { handleSort("client"); setAsc(!asc) }}>
                          <div className="header_center"> Client <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div>   </th>}
                        {resultData?.data?.date && <th onClick={() => { handleSort("date"); setAsc(!asc) }}>
                          <div className="header_center"> Date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}
                        {resultData?.data?.campaign && <th onClick={() => { handleSort("campaign"); setAsc(!asc) }}>
                          <div className="header_center"> Campaign <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}
                        {resultData?.data?.format && <th onClick={() => { handleSort("format"); setAsc(!asc) }}>
                          <div className="header_center">   Format <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}
                        {resultData?.data?.country && <th onClick={() => { handleSort("country"); setAsc(!asc) }}>Country <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {resultData?.data?.LineItem && <th onClick={() => { handleSort("lineitem"); setAsc(!asc) }}>Line Item  <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {resultData?.data?.OfferType && <th onClick={() => { handleSort("offer"); setAsc(!asc) }}>Offer Type<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {resultData?.data?.inventory_type && <th onClick={() => { handleSort("inventioryType"); setAsc(!asc) }}>Inventory Type<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {resultData?.data?.creative && <th onClick={() => { handleSort("creative"); setAsc(!asc) }}>Creatives <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {startEndDate && <th onClick={() => { handleSort("startDate"); setAsc(!asc) }}>Start Date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {startEndDate && <th onClick={() => { handleSort("endDate"); setAsc(!asc) }}>End Date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {impressionView && <th onClick={() => { handleSort("impressions"); setAsc(!asc) }}>Impressions <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {viewsView && <th onClick={() => { handleSort("views"); setAsc(!asc) }}>
                          <div className="header_center">Views <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div></th>}
                        {clicksView && <th onClick={() => { handleSort("clicks"); setAsc(!asc) }}>
                          <div className="header_center">Clicks <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}
                        {EngagmentView && <th onClick={() => { handleSort("engagements"); setAsc(!asc) }}>
                          <div className="header_center">Engagements <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div></th>}
                        {VtrView && <th onClick={() => { handleSort("vtr"); setAsc(!asc) }}>
                          <div className="header_center">VTR <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div></th>}
                        {CtrView && <th onClick={() => { handleSort("ctr"); setAsc(!asc) }}>
                          <div className="header_center">CTR <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div></th>}
                        {ErView && <th onClick={() => { handleSort("er"); setAsc(!asc) }}>
                          <div className="header_center">ER <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div></th>}
                        {spent && <th onClick={() => { handleSort("spentUsd"); setAsc(!asc) }}>Spent (USD)<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {spentCurrency && <th onClick={() => { handleSort("spent"); setAsc(!asc) }}>Spent Currency Deal<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {/* {spentCurrency && <th>Currency Code</th>} */}
                        {cpm && <th onClick={() => { handleSort("cpm"); setAsc(!asc) }}>CPM USD<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {cpv && <th onClick={() => { handleSort("cpv"); setAsc(!asc) }}>CPV USD<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {overviewData?.map((data, index) => {
                        return (
                          <>
                            <tr key={index}>
                              {resultData?.data?.Client && <td>{data?.client ? data?.client : "Not Available"}</td>}
                              {resultData?.data?.date && <td><Moment format="DD/MM/YYYY">{data?.date}</Moment></td>}
                              {resultData?.data?.campaign && (
                                <td class="text-truncate" title={data?.campaign}>{data?.campaign}</td>
                              )}
                              {resultData?.data?.format && <td>{data?.format}</td>}
                              {resultData?.data?.country && (
                                <td>{data?.country}</td>
                              )}
                              {resultData?.data?.LineItem && (
                                <td class="text-truncate" title={data?.lineitem}>
                                  {data?.lineitem || "Not Available"}
                                </td>
                              )}
                              {resultData?.data?.OfferType && (
                                <td>{data?.offer}</td>
                              )}
                              {resultData?.data?.inventory_type && (
                                <td>{data?.inventioryType}</td>
                              )}
                              {resultData?.data?.creative && (
                                <td title={data?.creative}>
                                  {data?.creative
                                    ? data?.creative.length < 15 ? (
                                      data?.creative
                                    ) :
                                      data?.creative.substring(0, 15) + '...'
                                    : "Not Available"}
                                </td>
                              )}

                              {startEndDate && <td>{data?.startDate}</td>}
                              {startEndDate && <td>{data?.endDate}</td>}
                              {impressionView && (
                                <td>{data?.impressions?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                              )}
                              {viewsView && (
                                <td>{data?.views?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                              )}
                              {clicksView && (
                                <td>{data?.clicks?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                              )}
                              {EngagmentView && (
                                <td>{data?.engagements?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                              )}
                              {VtrView && <td>{data?.views != 0 ? (data?.vtr)?.toFixed(2).toLocaleString('en-US', { maximumFractionDigits: 2 }) : "-"}%</td>}

                              {CtrView && <td>{data?.ctr?.toLocaleString('en-US', { maximumFractionDigits: 2 })}%</td>}
                              {ErView && <td>{data?.er?.toLocaleString('en-US', { maximumFractionDigits: 2 }) || 0}%</td>}
                              {spent && <td>{data?.spentUsd?.toLocaleString('en-US', { maximumFractionDigits: 2 }) || 0}</td>}
                              {spentCurrency && <td>{data?.spent?.toLocaleString('en-US', { maximumFractionDigits: 2 }) || 0}</td>}
                              {/* {spentCurrency && <td><span className="text-danger">{data?.currencyCode}</span></td>} */}
                              {cpm && <td>{data?.cpm?.toFixed(2).toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>}
                              {cpv && <td>{data?.cpv?.toFixed(4).toLocaleString('en-US', { maximumFractionDigits: 4 })}</td>}
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          )}
        {/* showPlacementsReport */}
        {showPlacementsReport && !generalReport && (
          showPlacementDescription ? (
            <div className="showPlacementDescription divDropdownPlacementsPaddingBottom">
              To see Placements Reports you must select the dimension LineItem, select a Client and select a Campaign
            </div>
          ) :
            (
              <>
                <div className={`divDropdownPlacements ${placementData.length == 0 ? 'divDropdownPlacementsPaddingBottom' : ''}`}>
                  <Form.Select
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      const selectedItem = overviewData?.find((item) => item.lineitemID === Number(selectedId));
                      setLineitemSelected({ name: selectedItem?.lineitem, id: selectedItem?.lineitemID, type: selectedItem?.inventioryType });
                      handlePlacementData(selectedId);
                    }}
                    className="dropdownPlacements"
                  >
                    <option selected={true} disabled={true}>
                      Select a line Item
                    </option>
                    {[...new Set(overviewData.map(data => data.lineitemID))].map(id => {
                      const data = overviewData.find(item => item.lineitemID === id);
                      return (
                        <option key={id} value={id}>
                          {data?.lineitem}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                {placementData.length > 0 &&
                  <div className="content">
                    <div className="card report__table">
                      <div className="text-end pt-2 pb-2 pe-2">
                        <button className="button expo_button" onClick={exportCsvPlacements}>
                          Export
                        </button>
                      </div>
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th onClick={() => handleSortPlacements("placementName")}>
                              Placement name
                              <span>&nbsp;</span>
                              <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                              </svg>
                            </th>
                            <th onClick={() => handleSortPlacements("audience")}>
                              Audience
                              <span>&nbsp;</span>
                              <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                              </svg>
                            </th>
                            <th onClick={() => handleSortPlacements("gender")}>
                              Gender
                              <span>&nbsp;</span>
                              <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                              </svg>
                            </th>
                            <th onClick={() => handleSortPlacements("impressions")}>
                              Impressions
                              <span>&nbsp;</span>
                              <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                              </svg>
                            </th>
                            <th onClick={() => handleSortPlacements("completeViews")}>
                              {
                                lineitemSelected.type === "Youtube" ? "True Views" : "Complete Views"
                              }
                              <span>&nbsp;</span>
                              <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                              </svg>
                            </th>
                            <th onClick={() => handleSortPlacements("vtr")}>
                              VTR
                              <span>&nbsp;</span>
                              <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                              </svg>
                            </th>
                            {lineitemSelected.type !== "Youtube" && (
                              <>
                                <th onClick={() => handleSortPlacements("ctr")}>
                                  CTR
                                  <span>&nbsp;</span>
                                  <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                                  </svg>
                                </th>
                                <th onClick={() => handleSortPlacements("clicks")}>
                                  Clicks
                                  <span>&nbsp;</span>
                                  <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                                  </svg>
                                </th>
                              </>
                            )}
                            <th onClick={() => handleSortPlacements("spent")}>
                              Spent Currency
                              <span>&nbsp;</span>
                              <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                              </svg>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {placementData?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data?.placementName}</td>
                                <td>{data?.audience}</td>
                                <td>{data?.gender}</td>
                                <td>{(data?.impressions).toLocaleString('en-US')}</td>
                                <td>{(data?.completeViews).toLocaleString('en-US')}</td>
                                <td>{data.vtr ? (data?.vtr).toLocaleString('en-US', { maximumFractionDigits: 2 }) + "%" : "-"}</td>
                                {lineitemSelected.type !== "Youtube" && (
                                  <>
                                    <td>{data?.ctr ? (data?.ctr).toLocaleString('en-US', { maximumFractionDigits: 2 }) + "%" : "-"}</td>
                                    <td>{data?.clicks ? (data?.clicks).toLocaleString('en-US', { maximumFractionDigits: 2 }) : "-"}</td>
                                  </>
                                )}
                                <td>{data?.spent ? (data?.spent).toLocaleString('en-US', { maximumFractionDigits: 2 }) : "-"}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                }
                {showPlacementDataNotFound &&
                  <div className="showPlacementDescription divDropdownPlacementsPaddingBottom">
                    There is no data related to lineitem and date selected
                  </div>
                }
              </>
            )
        )}
      </div>
    </>
  );
};
export default ReportsSaas;
