const baseURL = process.env.REACT_APP_DOT_NET_BASE_URL;

export const getAdvertisers = async () => {
  try {
    const response = await fetch(`${baseURL}report/get_advertiser`, {
      method: 'GET',
      headers: {
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getCampaigns = async (advertisers) => {
  try {
    const advertiserIds = advertisers.map(advertiser => advertiser.id);
    const raw = JSON.stringify({
      "advertiserId": advertiserIds.length ? advertiserIds: []
    });

    const response = await fetch(`${baseURL}report/get_reportcampaign`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data?.Campaign;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getCountries = async () => {
  try {
    const response = await fetch(`${baseURL}countries/getCountries`, {
      method: 'GET',
      headers: {
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();

    const updatedData = data.data.map(item => ({
      ...item,
      name: item.value,
      value: undefined
    }));
  
    return updatedData
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getMarkets = async () => {
  try {
    const response = await fetch(`${baseURL}campaignManager/pipeline-filter`, {
      method: 'GET',
      headers: {
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();

    let updatedData = data.data.map(item => ({
      name: item.label,
      id: item.pipelineId
    }));

    updatedData = updatedData.filter(item => 
      ['Brasil', 'México', 'ROLA', 'Prospección ROW', 'Regional - Latam'].includes(item.name)
    );
  
    return updatedData
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getInventories = async () => {
  try {
    const response = await fetch(`${baseURL}campaignManager/inventories`, {
      method: 'GET',
      headers: {
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();

    const filteredData = data.data.filter(item => 
      item.description === "Apps" || item.description === "Youtube"
    );

    const updatedData = filteredData.map(item => ({
      ...item,
      name: item.description,
      description: undefined
    }));
  
    return updatedData
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getLineitemBySearch = async (searchValue) => {
  try {
    const raw = JSON.stringify({
      advertiserId: null,
      searchValue: searchValue
    })
    const response = await fetch(`${baseURL}report/get_lineitembysearch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getLineitemByFilter = async (advertisers, clients, campaigns) => {
  try {
    const clientIds = clients.map(client => client.id);
    const raw = JSON.stringify({
      "advertiser": advertisers,
      "client": clientIds ? clientIds : [],
      "campaign": campaigns,
    });

    const response = await fetch(`${baseURL}report/get_lineitembyfilter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getLineitemBySearchSaas = async (advertiserId, searchValue, advertiserClientsId) => {
  try {
    const raw = JSON.stringify({
      advertiserId: advertiserId,
      searchValue: searchValue,
      advertiserClient: advertiserClientsId
    })

    const response = await fetch(`${baseURL}reportsaas/get_lineitembysearch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getLineitemByFilterSaas = async (advertisers, clients, campaigns) => {
  try {
    const raw = JSON.stringify({
      "advertiser": advertisers,
      "client": clients,
      "campaign": campaigns,
    });

    const response = await fetch(`${baseURL}reportsaas/get_lineitembyfilter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getAdvertiserSaas = async () => {
  try {
    const response = await fetch(`${baseURL}ReportSaas/get_reportadveriser`, {
      method: 'GET',
      headers: {
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getClient = async (advertisers) => {
  try {
    const advertiserIds = advertisers.map(advertiser => advertiser.id);
    const raw = JSON.stringify({
      "advertiserid": advertiserIds.length ? advertiserIds: []
    });

    const response = await fetch(`${baseURL}ReportSaas/get_client`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    const updatedData = data?.data.map(item => ({
      ...item,
      name: item.companyName,
      description: undefined
    }));

    return updatedData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getCampaignSaas = async (advertisers, clients) => {
  try {
    const advertiserIds = advertisers.map(advertiser => advertiser.id);
    const clientIds = clients.map(client => client.id);
    const raw = JSON.stringify({
      "advertiser_client_Id": advertiserIds.length ? advertiserIds: [],
      "clientsId": clientIds.length ? clientIds : null
    });

    const response = await fetch(`${baseURL}ReportSaas/get_reportcampaign`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data?.Campaign;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getPerformanceReport = async (dimension, advertisers, campaigns, startDate, endDate, period,
  billingOptions, inventoryType, market, lineitems, currency, spentCurrency, cpmCurrency, cpvCurrency,
  inversionBudgetCurrency) => {
  try {
    if (dimension.includes('lineitem') && !dimension.includes('lineitem_id')) {
      dimension.push('lineitem_id');
    }

    if (dimension.includes('campaign') && !dimension.includes('campaign_id')) {
      dimension.push('campaign_id');
    }

    if (dimension.includes('creative') && !dimension.includes('creative_id')) {
      dimension.push('creative_id');
    }

    if (dimension.includes('country_youtube') && !dimension.includes('country_app')) {
      inventoryType.push('Youtube');      
    }

    var raw = JSON.stringify({
      advertiser: advertisers,
      campaign: campaigns,
      format: null,
      startDate: startDate,
      endDate: endDate,
      dimension: dimension,
      period: period,
      currency: currency,
      spent_currency: spentCurrency,
      cpm_currency: cpmCurrency,
      cpv_currency: cpvCurrency,
      inversion_budget_currency: inversionBudgetCurrency,
      is_billable: billingOptions ? billingOptions : [],
      inventoryType: inventoryType,
      market: market,
      lineitemId: lineitems
    });

    const response = await fetch(`${baseURL}report/getPerformanceReport`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getPerformanceReportSaas = async (dimension, advertiserSaas, advertiserIdSaas, campaignSaas, startDate, endDate, period,
  inventoryType, clientsId, lineitems, currency, spentCurrency, cpmCurrency, cpvCurrency, inversionBudgetCurrency) => {
  try {
    if (dimension.includes('lineitem') && !dimension.includes('lineitem_id')) {
      dimension.push('lineitem_id');
    }

    if (dimension.includes('campaign') && !dimension.includes('campaign_id')) {
      dimension.push('campaign_id');
    }

    if (dimension.includes('creative') && !dimension.includes('creative_id')) {
      dimension.push('creative_id');
    }

    if(advertiserIdSaas > 0) {
      advertiserSaas = [];
    }

    if (dimension.includes('country_youtube') && !dimension.includes('country_app')) {
      inventoryType.push('Youtube');      
    }

    var raw = JSON.stringify({
      advertiser: advertiserSaas,
      userAdvertiserId: advertiserIdSaas, // When role!=1 use this advertiserId
      campaign: campaignSaas,
      format: null,
      startDate: startDate,
      endDate: endDate,
      dimension: dimension,
      period: period,
      currency: currency,
      spent_currency: spentCurrency,
      cpm_currency: cpmCurrency,
      cpv_currency: cpvCurrency,
      inversion_budget_currency: inversionBudgetCurrency,
      is_billable: [],
      inventoryType: inventoryType,
      market: null,
      clientsId: clientsId,
      lineitemId: lineitems
    });

    const response = await fetch(`${baseURL}reportsaas/getPerformanceReport`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }
    const data = await response.json();
    return data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

