import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import {MdOutlineCancel } from "react-icons/md";

import "./style.css";


const SearcherInput = (props) => {
  const [numberLetters, setNumberLetters] = useState('');

  const handleOnChange = (e) => {
    setNumberLetters(e.target.value);
    props.onChange(e.target.value);
  };

  const handleOnClickCancel = () => {
    setNumberLetters('');
    props.onChange('');
  };

  return (
    <div className={`regularSearchDiv ${props.divStyle}`}>
      <AiOutlineSearch size={props.iconSize} className={`regularSearchIcon ${props.iconStyle}`}/>
      <input onChange={handleOnChange} value={numberLetters} placeholder={props.placeholder} className={`regularSearchInput ${props.inputStyle}`}/>
      {numberLetters.length > 0 &&
        <div className="cancelSearch">        
          <MdOutlineCancel
            className="cursorPointer"
            size={16}
            onClick={handleOnClickCancel}
          />
        </div>
      }
    </div>
  );
};

export default SearcherInput;