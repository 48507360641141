import React from 'react';
import logo from './logo.svg';
import './App.css';
// import "react-range-slider-input/dist/style.css";
import {
  BrowserRouter as Router,
  Route,
  Routes

} from "react-router-dom";
import Login from './components/Auth/login';
import Forgot from './components/Auth/forgot';
import Reports from './components/Reports/reports';
import ReportsSaas from './components/Reports/reportSaas';
import Sidebar from './components/Sidebar/sidebar';
import Header from './components/Header/header';
import Home from './components/MainContent/home';
import HomeSaas from './components/MainContent/homeSaas';
import Profile from './components/Profile/profile';
import ChangePassword from './components/Profile/changePassword';

import Userlist from './components/users/userlist';
import Adduser from './components/users/adduser';
import Updateuser from './components/users/updateuser';
import { VerifyAuth } from './middlewares/AuthVerify';
import { VerifyType } from './middlewares/typeVerify';
import Abstract from './components/Abstract';
import Calculater from './components/Abstract/calculater';
import Variable from './components/Variable';
import CampaignPage from './components/Campaign';
import Mediaplan from './components/Campaign/mediaplan';
import CompaignName from './components/Campaign/compaignName';
import NewCompaign from './components/Campaign/newCompaign';
import NewLineItem from './components/Campaign/newLineItem';
import LineItems from './components/Campaign/lineItems';
import NewCreatives from './components/Campaign/newCreatives';
import Creatives from './components/Campaign/creatives';
import MediaPlanner from './components/MediaPlanner';
import CampaignList from './components/Campaign/CampaignList'
import CreativeView from './components/Campaign/creativeView';
import { ViewZip } from './components/Campaign/viewzip';
import CampaignCreatives from './components/Campaign/campaignCreatives';
import ViewTag from './components/Campaign/viewtag';
import StepForm from './components/StepForm';
import CampaignPageSaas from './components/CampaignManagerSaas';
import MediaplanSaas from './components/CampaignManagerSaas/mediaplanSaas';
import NewCompaignSaas from './components/CampaignManagerSaas/newCompaignSaas';
import NewCreativesSaas from './components/CampaignManagerSaas/newCreativesSaas';
import CreativesSaas from './components/CampaignManagerSaas/creativesSaas';
import CompaignNameSaas from './components/CampaignManagerSaas/compaignNameSaas';
import NewLineItemSaas from './components/CampaignManagerSaas/newLineItemSaas';
import CampaignListSaas from './components/CampaignManagerSaas/CampaignListSaas';
import CreativeViewSaas from './components/CampaignManagerSaas/creativeViewSaas';
import { ViewZipSaas } from './components/CampaignManagerSaas/viewzipSaas';
import ViewTagSaas from './components/CampaignManagerSaas/viewtagSaas';
import LineItemsSaas from './components/CampaignManagerSaas/lineItemsSaas';
import AdminSaas from './components/AdminSaas';
import Resetpassword from './components/Auth/resetpassword';
import ChangeUserPassword from './components/Auth/changeUserPassword';
import ReportRCFinance from './components/Reports/reportRcFinance'
import LineItemshistory from './components/Campaign/lineItemshistory';
import ClientServiceFee from './components/AdminSaas/clientServiceFee';
import ClientServicehistory from './components/AdminSaas/clientServiceHistory';
import EditSubgroup from './components/users/editSubgroup';
import CampaignHistory from './components/Campaign/campaignHistory';
import AdvanceReports from './components/Reports/advanceReports';

function App() {
  return (

    <Router>


      <Routes>
        <Route path="/" element={<Login />} />
        <Route exact path="/forgot" element={<Forgot />} />
        <Route exact path="/userlist" element={<VerifyAuth><VerifyType><Sidebar /><Header /><Userlist /></VerifyType></VerifyAuth>} />
        <Route exact path="/home" element={<VerifyAuth><Sidebar /><Header /><Home /></VerifyAuth>} />
        <Route exact path="/homeSaas" element={<VerifyAuth><Sidebar /><Header /><HomeSaas /></VerifyAuth>} />
        <Route exact path="/changeUserPassword/:token" element={<ChangeUserPassword />} />
        <Route exact path="/abstract" element={<VerifyAuth><Sidebar /><Header /><Abstract /></VerifyAuth>} />
        <Route exact path="/calculater" element={<VerifyAuth><Sidebar /><Header /><Calculater /></VerifyAuth>} />
        <Route exact path="/reports" element={<VerifyAuth><Sidebar /><Header /><Reports /></VerifyAuth>} />
        <Route exact path="/reportsSaas" element={<VerifyAuth><Sidebar /><Header /><ReportsSaas /></VerifyAuth>} />
        <Route exact path="/report-rc-finance" element={<VerifyAuth><Sidebar /><Header /><ReportRCFinance /></VerifyAuth>} />
        <Route exact path="/advanceReports" element={<VerifyAuth><Sidebar /><Header /><AdvanceReports /></VerifyAuth>} />
        <Route exact path="/advanceReportsSaas" element={<VerifyAuth><Sidebar /><Header /><AdvanceReports /></VerifyAuth>} />
        <Route exact path="/campaign" element={<VerifyAuth><Sidebar /><Header /><CampaignPage /></VerifyAuth>} />
        <Route exact path="/campaignsaas" element={<VerifyAuth><Sidebar /><Header /><CampaignPageSaas /></VerifyAuth>} />
        <Route exact path="/campaign/mediaplan" element={<VerifyAuth><Sidebar /><Header /><Mediaplan /></VerifyAuth>} />
        <Route exact path="/campaignsaas/Mediaplansaas" element={<VerifyAuth><Sidebar /><Header /><MediaplanSaas /></VerifyAuth>} />
        <Route exact path="/campaign/newCompaign" element={<VerifyAuth><Sidebar /><Header /><NewCompaign /></VerifyAuth>} />
        <Route exact path="/campaignsaas/newCompaignsaas" element={<VerifyAuth><Sidebar /><Header /><NewCompaignSaas /></VerifyAuth>} />
        <Route exact path="/campaign/newCreatives" element={<VerifyAuth><Sidebar /><Header /><NewCreatives /></VerifyAuth>} />
        <Route exact path="/campaignsaas/NewCreativessaas" element={<VerifyAuth><Sidebar /><Header /><NewCreativesSaas /></VerifyAuth>} />
        <Route exact path="/campaign/creatives" element={<VerifyAuth><Sidebar /><Header /><Creatives /></VerifyAuth>} />
        <Route exact path="/campaignsaas/Creativessaas" element={<VerifyAuth><Sidebar /><Header /><CreativesSaas /></VerifyAuth>} />
        <Route exact path="/compaignName" element={<VerifyAuth><Sidebar /><Header /><CompaignName /></VerifyAuth>} />
        <Route exact path="/CompaignNamesaas" element={<VerifyAuth><Sidebar /><Header /><CompaignNameSaas /></VerifyAuth>} />
        <Route exact path="/campaign/lineitems" element={<VerifyAuth><Sidebar /><Header /><LineItems /></VerifyAuth>} />
        <Route exact path="/campaignsaas/LineItemsSaas" element={<VerifyAuth><Sidebar /><Header /><LineItemsSaas /></VerifyAuth>} />
        <Route exact path="/campaign/NewLineItem" element={<VerifyAuth><Sidebar /><Header /><NewLineItem /></VerifyAuth>} />
        <Route exact path="/campaignsaas/NewLineItemsaas" element={<VerifyAuth><Sidebar /><Header /><NewLineItemSaas /></VerifyAuth>} />
        <Route exact path="/variable" element={<VerifyAuth><VerifyType><Sidebar /><Header /><Variable /></VerifyType></VerifyAuth>} />
        <Route exact path="/lineItemshistory" element={<VerifyAuth><VerifyType><Sidebar /><Header /><LineItemshistory /></VerifyType></VerifyAuth>} />
        <Route exact path="/campaignhistory" element={<VerifyAuth><VerifyType><Sidebar /><Header /><CampaignHistory /></VerifyType></VerifyAuth>} />
        <Route exact path="/profile" element={<VerifyAuth><Sidebar /><Header /><Profile /></VerifyAuth>} />
        <Route exact path="/changePassword" element={<VerifyAuth><Sidebar /><Header /><ChangePassword /></VerifyAuth>} />
        <Route exact path="/addUser" element={<VerifyAuth><Sidebar /><Header /><Adduser /></VerifyAuth>} />
        <Route exact path="/updateUser/:id" element={<VerifyAuth><Sidebar /><Header /><Updateuser /></VerifyAuth>} />
        <Route exact path="/mediaplanner" element={<VerifyAuth><Sidebar /><Header /><MediaPlanner /></VerifyAuth>} />
        <Route exact path="/campaignlist/:id" element={<VerifyAuth><Sidebar /><Header /><CampaignList /></VerifyAuth>} />
        <Route exact path="/CampaignListsaas/:id" element={<VerifyAuth><Sidebar /><Header /><CampaignListSaas /></VerifyAuth>} />
        <Route exact path="/creativeview/:id" element={<VerifyAuth><Sidebar /><Header /><CreativeView /></VerifyAuth>} />
        <Route exact path="/Creativeviewsaas/:id" element={<VerifyAuth><Sidebar /><Header /><CreativeViewSaas /></VerifyAuth>} />
        <Route exact path="/viewzip" element={<VerifyAuth><Sidebar /><Header /><ViewZip /></VerifyAuth>} />
        <Route exact path="/ViewzipSaas" element={<VerifyAuth><Sidebar /><Header /><ViewZipSaas /></VerifyAuth>} />
        <Route exact path="/campaign-creatives/" element={<VerifyAuth><Sidebar /><Header /><CampaignCreatives /></VerifyAuth>} />
        <Route exact path="/viewtag" element={<VerifyAuth><Sidebar /><Header /><ViewTag /></VerifyAuth>} />
        <Route exact path="/ViewTagSaas" element={<VerifyAuth><Sidebar /><Header /><ViewTagSaas /></VerifyAuth>} />
        <Route exact path="/stepform" element={<VerifyAuth><Sidebar /><Header /><StepForm /></VerifyAuth>} />
        <Route exact path="/adminSaas" element={<VerifyAuth><Sidebar /><Header /><AdminSaas /></VerifyAuth>} />
        <Route exact path="/adminSaas/clientServiceFee" element={<VerifyAuth><Sidebar /><Header /><ClientServiceFee /></VerifyAuth>} />
        <Route exact path="/adminSaas/clientServiceHistory" element={<VerifyAuth><Sidebar /><Header /><ClientServicehistory /></VerifyAuth>} />
        <Route exact path="/passwordrecovery/:token" element={<Resetpassword />} />
        <Route exact path="/editSubgroup" element={<VerifyAuth><Sidebar /><Header /><EditSubgroup /></VerifyAuth>} />

      </Routes>

    </Router>
    // <Login />
  );
}


export default App;
