import i18n from "../../../i18n";

const rowReportsList = [
  {reportType: "both", role: 1, value: i18n.advanceReportsAdvertiser},
  {reportType: "fixed", value: i18n.advanceReportsIDHSDeal},
  {reportType: "both", value: i18n.advanceReportsCampaign},
  {reportType: "both", value: i18n.advanceReportsCampaignId},
  {reportType: "both", value: i18n.advanceReportsLineItem},
  {reportType: "both", value: i18n.advanceReportsLineItemId},
  {reportType: "both", value: i18n.advanceReportsCreative},
  {reportType: "both", value: i18n.advanceReportsCreativeId},
  {reportType: "both", value: i18n.advanceReportsFormat},
  {reportType: "both", value: i18n.advanceReportsDate},
  {reportType: "both", value: i18n.advanceReportsOfferType},
  {reportType: "both", value: i18n.advanceReportsInventoryType},
  {reportType: "both", value: i18n.advanceReportsCountryApp},
  {reportType: "both", value: i18n.advanceReportsCountryYoutube},
  {reportType: "both", value: i18n.advanceReportsDevices},
  {reportType: "both", value: i18n.advanceReportsAppName},
  {reportType: "both", value: i18n.advanceReportsYoutubeChannel},
  {reportType: "saas", value: i18n.advanceReportsClient}
]

export default rowReportsList;