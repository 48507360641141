import { useState, useMemo, useEffect } from "react";
import SortableHeader from "./sortableHeader";
import i18n from "../../../i18n";
import { incompatibleFormatEngagements, incompatibleFormatViews } from "../constants/incompatibleFormats";


const TablePerformanceReport = (props) => {
  const [sortConfig, setSortConfig] = useState({ key: 'impressions', direction: 'desc' });
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    setTableData(props.data);
  }, [props.data]);

  const sortedData = useMemo(() => {
    if (sortConfig.key && tableData !==null) {
      return [...tableData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return tableData;
  }, [tableData, sortConfig]);

  const handleSort = (field) => {
    const direction = sortConfig.key === field && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key: field, direction });
  };

  return (
    tableData &&
      <table className="tablePerformanceReport">
        <thead>
          <tr className="trPerformanceReport">
            {props.selectedRows.find(item => item === i18n.advanceReportsIDHSDeal) && (
              <SortableHeader
                field="dealId"
                label={i18n.advanceReportsIDHSDeal}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsAdvertiser) &&              
              <SortableHeader
                field="advertiser"
                label={i18n.advanceReportsAdvertiser}
                sortConfig={sortConfig}
                onSort={handleSort}
            />
            }
            {props.selectedRows.find(item => item === i18n.advanceReportsClient) && (
              <SortableHeader
                field="client"
                label={i18n.advanceReportsClient}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsCampaignId) && (
              <SortableHeader
                field="campaignId"
                label={i18n.advanceReportsCampaignId}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsCampaign) && (
              <SortableHeader
                field="campaign"
                label={i18n.advanceReportsCampaign}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsLineItemId) && (
              <SortableHeader
                field="lineitemId"
                label={i18n.advanceReportsLineItemId}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsLineItem) && (
              <SortableHeader
                field="lineItem"
                label={i18n.advanceReportsLineItem}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsCreativeId) && (
              <SortableHeader
                field="creativeId"
                label={i18n.advanceReportsCreativeId}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsCreative) && (
              <SortableHeader
                field="creative"
                label={i18n.advanceReportsCreative}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsInventoryType) && (
              <SortableHeader
                field="inventoryType"
                label={i18n.advanceReportsInventoryType}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsFormat) && (
              <SortableHeader
                field="format"
                label={i18n.advanceReportsFormat}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsOfferType) && (
              <SortableHeader
                field="offerType"
                label={i18n.advanceReportsOfferType}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {(props.selectedRows.find(item => item === i18n.advanceReportsCountryApp) && props.selectedRows.find(item => item === i18n.advanceReportsCountryYoutube)) && (
              <SortableHeader
                field="country"
                label={i18n.advanceReportsCountry}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {(props.selectedRows.find(item => item === i18n.advanceReportsCountryApp) && !props.selectedRows.find(item => item === i18n.advanceReportsCountryYoutube)) && (
              <SortableHeader
                field="countryApp"
                label={i18n.advanceReportsCountryApp}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {(props.selectedRows.find(item => item === i18n.advanceReportsCountryYoutube) && !props.selectedRows.find(item => item === i18n.advanceReportsCountryApp)) && (
              <SortableHeader
                field="countryYoutube"
                label={i18n.advanceReportsCountryYoutube}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsAppName) && (
              <SortableHeader
                field="placement"
                label={i18n.advanceReportsAppName}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsYoutubeChannel) && (
              <SortableHeader
                field="placement"
                label={i18n.advanceReportsYoutubeChannel}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsDevices) && (
              <SortableHeader
                field="devices"
                label={i18n.advanceReportsDevices}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedRows.find(item => item === i18n.advanceReportsDate) && (
              <SortableHeader
                field="date"
                label={i18n.advanceReportsDate}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsStartDate) && (
              <SortableHeader
                field="startDate"
                label={i18n.advanceReportsStartDate}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsEndDate) && (
              <SortableHeader
                field="endDate"
                label={i18n.advanceReportsEndDate}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsGoal) && (
              <SortableHeader
                field="goal"
                label={i18n.advanceReportsGoal}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsImpressions) && (
              <SortableHeader
                field="impressions"
                label={i18n.advanceReportsImpressions}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsViews) && (
              <SortableHeader
                field="completeViewsVideo"
                label={i18n.advanceReportsViews}
                sortConfig={sortConfig}
                onSort={handleSort}
                infoIcon={(props.selectedRows.find(item => item === i18n.advanceReportsCountryYoutube) || props.selectedRows.find(item => item === i18n.advanceReportsYoutubeChannel)) ? true : false}
                infoIconMessage={i18n.advanceReportsViewsInfoMessage}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsQuartiles) && (
              <>
                <SortableHeader
                  field="firstQuartile"
                  label={i18n.advanceReportsFirstQuartile}
                  sortConfig={sortConfig}
                  onSort={handleSort}
                />
                <SortableHeader
                  field="midQuartile"
                  label={i18n.advanceReportsMidQuartile}
                  sortConfig={sortConfig}
                  onSort={handleSort}
                />
                <SortableHeader
                  field="thirdQuartile"
                  label={i18n.advanceReportsThirdQuartile}
                  sortConfig={sortConfig}
                  onSort={handleSort}
                />
              </>
            )}            
            {props.selectedColumns.find(item => item === i18n.advanceReportsClicks) && (
              <SortableHeader
                field="clicks"
                label={i18n.advanceReportsClicks}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsEngagements) && (
              <SortableHeader
                field="engagements"
                label={i18n.advanceReportsEngagements}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsVTR) && (
              <SortableHeader
                field="vtr"
                label={i18n.advanceReportsVTR}
                sortConfig={sortConfig}
                onSort={handleSort}
                infoIcon={true}
                infoIconMessage={i18n.advanceReportsVtrInfoMessage}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsCTR) && (
              <SortableHeader
                field="ctr"
                label={i18n.advanceReportsCTR}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsER) && (
              <SortableHeader
                field="er"
                label={i18n.advanceReportsER}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsCurrency) && props.isCurrencyReport && (
              <SortableHeader
                field="currencyCode"
                label={i18n.advanceReportsCurrency}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsInversionBudgetCurrency) && props.isCurrencyReport && (
              <SortableHeader
                field="inversionBudget"
                label={i18n.advanceReportsInversionBudgetCurrency}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsInversionBudgetUSD) && (
              <SortableHeader
                field="inversionBudgetUsd"
                label={i18n.advanceReportsInversionBudgetUSD}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsSpentCurrencyDeal) && props.isCurrencyReport && (
              <SortableHeader
                field="spent"
                label={i18n.advanceReportsSpentCurrencyDeal}
                sortConfig={sortConfig}
                onSort={handleSort}
                headerLarge={true}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsSpentUSD) && (
              <SortableHeader
                field="spentUsd"
                label={i18n.advanceReportsSpentUSD}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsCpmCurrency) && props.isCurrencyReport && (
              <SortableHeader
                field="cpmCurrency"
                label={i18n.advanceReportsCpmCurrency}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsCpm) && (
              <SortableHeader
                field="cpm"
                label={i18n.advanceReportsCpm}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsCpvCurrency) && props.isCurrencyReport && (
              <SortableHeader
                field="cpvCurrency"
                label={i18n.advanceReportsCpvCurrency}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
            {props.selectedColumns.find(item => item === i18n.advanceReportsCpv) && (
              <SortableHeader
                field="cpv"
                label={i18n.advanceReportsCpv}
                sortConfig={sortConfig}
                onSort={handleSort}
              />
            )}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index} className="trPerformanceReport">
              {props.selectedRows.find(item => item === i18n.advanceReportsIDHSDeal) && <td className="tdPerformanceReport tdPerformanceReportRight">{item.dealId}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsAdvertiser) && <td className="tdPerformanceReport tdPerformanceReportLarge">{item.advertiser}{item.advertiserCountry ? ' - ' + item.advertiserCountry : ''}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsClient) && <td className="tdPerformanceReport tdPerformanceReportLarge">{item.client}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsCampaignId) && <td className="tdPerformanceReport tdPerformanceReportRight">{item.campaignId != null ? item.campaignId : '-'}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsCampaign) && <td className="tdPerformanceReport tdPerformanceReportLarge">{item.campaign}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsLineItemId) && <td className="tdPerformanceReport tdPerformanceReportRight">{item.lineitemId != null ? item.lineitemId : '-'}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsLineItem) && <td className="tdPerformanceReport tdPerformanceReportLarge">{item.lineItem}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsCreativeId) && <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.creativeId ? item.creativeId : '-'}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsCreative) && <td className="tdPerformanceReport tdPerformanceReportLarge">{item.creative != null ? item.creative : '-'}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsInventoryType) && <td className="tdPerformanceReport">{item.inventoryType != null ? item.inventoryType : '-'}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsFormat) && <td className="tdPerformanceReport">{item.format != null ? item.format : '-'}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsOfferType) && <td className="tdPerformanceReport">{item.offerType}</td>}
              {(props.selectedRows.find(item => item === i18n.advanceReportsCountryApp) || props.selectedRows.find(item => item === i18n.advanceReportsCountryYoutube)) && <td className="thPerformanceReport">{item.country ? item.country : '-'}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsAppName) && <td className="thPerformanceReport">{item.placement != null ? item.placement : '-'}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsYoutubeChannel) && <td className="thPerformanceReport">{item.placement != null ? item.placement : '-'}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsDevices) && <td className="thPerformanceReport tdPerformanceReportMid">{item.device != null ? item.device : '-'}</td>}
              {props.selectedRows.find(item => item === i18n.advanceReportsDate) && <td className="tdPerformanceReport">{item.date && item.date.split('T')[0]}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsStartDate) && <td className="tdPerformanceReport">{item.startDate.split('T')[0]}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsEndDate) && <td className="tdPerformanceReport">{item.endDate.split('T')[0]}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsGoal) && <td className="tdPerformanceReport tdPerformanceReportRight">{item.goal != null ?item.goal.toLocaleString('en-US') : '-'}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsImpressions) && <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.impressions != null ? item.impressions.toLocaleString('en-US') : '-'}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsViews) && <td className="tdPerformanceReport tdPerformanceReportRight">{item.completeViewsVideo == null || incompatibleFormatViews.includes(item.format) ? '-' : item.completeViewsVideo.toLocaleString('en-US')}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsQuartiles) && <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.firstQuartile == null || incompatibleFormatViews.includes(item.format) ? "-" : item.firstQuartile.toLocaleString('en-US')}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsQuartiles) && <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.midQuartile == null || incompatibleFormatViews.includes(item.format) ? "-" : item.midQuartile.toLocaleString('en-US')}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsQuartiles) && <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.thirdQuartile == null || incompatibleFormatViews.includes(item.format) ? "-" : item.thirdQuartile.toLocaleString('en-US')}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsClicks) && <td className="tdPerformanceReport tdPerformanceReportRight">{item.clicks != null ? item.clicks.toLocaleString('en-US') : '-'}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsEngagements) && <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.engagements == null || incompatibleFormatEngagements.includes(item.format) ? '-' : item.engagements.toFixed(2)}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsVTR) && <td className="tdPerformanceReport tdPerformanceReportRight">{item.vtr == null || incompatibleFormatViews.includes(item.format) ? "-" : item.vtr.toFixed(2) + "%"}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsCTR) && <td className="tdPerformanceReport tdPerformanceReportRight">{item.ctr != null ? item.ctr.toFixed(2) + "%" : '-'}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsER) && <td className="tdPerformanceReport tdPerformanceReportRight">{item.er == null || incompatibleFormatEngagements.includes(item.format) ? '-' : item.er.toFixed(2) + "%"}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsCurrency) && props.isCurrencyReport && <td className="tdPerformanceReport">{item.currencyCode}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsInversionBudgetCurrency) && props.isCurrencyReport && <td className="tdPerformanceReport tdPerformanceReportRight">{item?.inversionBudget != null ? (parseFloat(item?.inversionBudget?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsInversionBudgetUSD) && <td className="tdPerformanceReport tdPerformanceReportRight">{item?.inversionBudgetUsd != null ? (parseFloat(item?.inversionBudgetUsd?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsSpentCurrencyDeal) && props.isCurrencyReport && <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item?.spent != null ? (parseFloat(item?.spent?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsSpentUSD) && <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item?.spentUsd != null ? (parseFloat(item?.spentUsd?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsCpmCurrency) && props.isCurrencyReport && <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.cpmCurrency != null ? item.cpmCurrency.toFixed(2).toLocaleString('en-US') : '-'}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsCpm) && <td className="tdPerformanceReport tdPerformanceReportRight">{item.cpm != null ? item.cpm.toFixed(2).toLocaleString('en-US') : '-'}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsCpvCurrency) && props.isCurrencyReport && <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.cpv != null ? item.cpvCurrency.toLocaleString('en-US') : '-'}</td>}
              {props.selectedColumns.find(item => item === i18n.advanceReportsCpv) && <td className="tdPerformanceReport tdPerformanceReportRight">{item.cpv != null ? item.cpv.toLocaleString('en-US') : '-'}</td>}
            </tr>
          ))}
        </tbody>
      </table>    
  )
}

export default TablePerformanceReport;