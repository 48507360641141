import i18n from "../../../i18n";

const dimensionsMap = [
  {dimension: i18n.advanceReportsAdvertiser, dimensionParameter: "advertiser"},
  {dimension: i18n.advanceReportsCampaign, dimensionParameter: "campaign"},
  {dimension: i18n.advanceReportsCampaignId, dimensionParameter: "campaign_id"},
  {dimension: i18n.advanceReportsCreative, dimensionParameter: "creative"},
  {dimension: i18n.advanceReportsCreativeId, dimensionParameter: "creative_id"},
  {dimension: i18n.advanceReportsLineItem, dimensionParameter: "lineitem"},
  {dimension: i18n.advanceReportsLineItemId, dimensionParameter: "lineitem_id"},
  {dimension: i18n.advanceReportsFormat, dimensionParameter: "format"},
  {dimension: i18n.advanceReportsDate, dimensionParameter: "date"},
  {dimension: i18n.advanceReportsOfferType, dimensionParameter: "offerType"},
  {dimension: i18n.advanceReportsInventoryType, dimensionParameter: "inventoryType"},
  {dimension: i18n.advanceReportsCountryApp, dimensionParameter: "country_app"},
  {dimension: i18n.advanceReportsCountryYoutube, dimensionParameter: "country_youtube"},
  {dimension: i18n.advanceReportsDevices, dimensionParameter: "device"},
  {dimension: i18n.advanceReportsAppName, dimensionParameter: "placement_app"},
  {dimension: i18n.advanceReportsYoutubeChannel, dimensionParameter: "placement_youtube"},
  {dimension: i18n.advanceReportsIDHSDeal, dimensionParameter: "deal_id"},
  {dimension: i18n.advanceReportsClient, dimensionParameter: "client"},
]

export default dimensionsMap;